import React from 'react';
import './BlackBar.scss'

const BlackBar = () => {
    return (
        <div className="blackBarStyle">

        </div>
    );
};

export default BlackBar;
