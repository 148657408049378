import React from 'react';
import './RulesPage.scss';
import SocialIcons from "../Components/SocialIcons"
import BlackBar from '../Components/BlackBar';
import ArrowDownButton from '../Components/ArrowDownButton';
import Fireflies from '../Components/Fireflies';

const RulesPage = () => {
    return (
        <div className="back_img">


            <Fireflies/>
            <ArrowDownButton />
            <div className="rules-page ">
                <h2>Дата последнего редактирования: 18 сентября 2024</h2>
                <h1>ОСНОВНЫЕ ТЕРМИНЫ</h1>
                <div className="rules-content">
                    <div className="rule">
                        <strong>Правило</strong> – требование для исполнения неких условий (норма поведения) всеми
                        Пользователями какого-либо действия (игры, правописания, организации, учреждения), за
                        невыполнение
                        которого предусмотрено наказание.
                    </div>
                    <div className="rule">
                        <strong>Наказание</strong> – определенное ограничение прав Пользователя в рамках проекта,
                        следующее
                        за нарушение таковых правил проекта. Основные виды: Блокировка, Исключение, Отключение чата.
                    </div>
                    <div className="rule">
                        <strong>Блокировка</strong> – временный или постоянный запрет на осуществление какой-либо
                        деятельности.
                    </div>
                    <div className="rule">
                        <strong>Брутить</strong> – взламывать, подбирать или генерировать пароли иных Пользователей.
                    </div>
                    <div className="rule">
                        <strong>Оскорбление</strong> – умышленное унижение чести и достоинства личности. Оскорбление
                        может
                        быть нанесено в виде высказывания или в виде действия.
                    </div>
                    <div className="rule">
                        <strong>Буллинг</strong> – психологический террор, травля одного человека другим. Буллинг
                        предполагает систематичность.
                    </div>
                    <div className="rule">
                        <strong>Разжигание розни</strong> – действия, способные задеть чувства определенной группы лиц,
                        основанные на определенных характеристиках этой группы, разжиганием розни так же считаются любые
                        уничижительные высказывания с упоминанием этой группы.
                    </div>
                    <div className="rule">
                        <strong>Мультиаккаунт</strong> – дополнительный аккаунт пользователя, зарегистрированный с
                        одного
                        устройства или айпи адреса.
                    </div>
                    <div className="rule">
                        <strong>MINECRAFT</strong> – компьютерная инди-игра в жанре песочницы, выпущенная студией Mojang
                        AB.
                    </div>
                    <div className="rule">
                        <strong>SURVIVAL</strong> – определенный режим игры MINECRAFT, что заключается в игровом
                        выживании
                        на сервере.
                    </div>
                    <div className="rule">
                        <strong>Staff</strong> – это определенный состав Пользователей, одобренный и исполняющий правила
                        на
                        сервере. Являются подгруппами Администрации проекта. Обозначаются префиксами:
                    </div>
                    <div className="rule">
                        <strong>Администрация</strong> – это основатели и доверенные лица проекта. Преследуют те же
                        функции
                        и обязанности, что и модерация. Имеют неограниченные полномочия в рамках проекта. Обозначаются
                        префиксами:
                    </div>
                </div>

                <h1>1. ОСНОВНЫЕ ПРАВИЛА</h1>
                <div className="rules-content">
                    <div className="rule">
                        <strong>1.1</strong> Регистрируясь на проекте, Пользователь соглашается со всеми правилами, а
                        также
                        с политикой
                        обработки персональных данных.
                    </div>
                    <div className="rule">
                        <strong>1.2</strong> Правила проекта состоят из правил сервера и правил vanilla и подлежат их
                        исполнению, в зависимости от выбранной категории участия в проекте.
                    </div>
                    <div className="rule">
                        <strong>1.3</strong> Нарушение правил, а также его наказумость и иные административные
                        последствия
                        определяются только:
                        Правилами режима VANILA( когда Пользователь зарегистрировался на проекте сервера игры
                        MINECRAFT),
                        Правилами форума и социальных сетей(при испольщовании Пользователем одной из официальных
                        источников
                        проекта),
                        Общими правилами (если Пользователь зарегистрировался либо принял участие в одном или всех
                        вышеописанных игровых режимов, источников).
                    </div>
                    <div className="rule">
                        <strong>1.4</strong> Применение наказания по аналогии не допускается.
                    </div>
                    <div className="rule">
                        <strong>1.5</strong> Пользователи, нарушившие правила проекта, равноправны и подлежат
                        административной ответственности проекта независимо от пола, расы, национальности, языка,
                        происхождения, имущественного и должностного положения, места жительства, отношения к религии,
                        убеждений, принадлежности к общественным объединениям, а также других обстоятельств.
                    </div>
                    <div className="rule">
                        <strong>1.6</strong> Пользователь подлежит наказанию только за те нарушения правил, в отношении
                        которых установлена его вина.
                    </div>
                    <div className="rule">
                        <strong>1.7</strong> Объективное вменение, то есть административная ответственность на проекте
                        за
                        невиновные действия, не допускается.
                    </div>
                    <div className="rule">
                        <strong>1.8</strong> Незнание правил не освобождает Пользователя от административной
                        ответственности
                        проекта. Будь то ошибкой,
                        непреднамеренным действием или иным, характерным по свойствам деянием.
                    </div>
                    <div className="rule">
                        <strong>1.9</strong> Пользователь несет полную ответственность за безопасность своего аккаунта и
                        обязуется соблюдать все надлежащие меры
                        по обеспечению его безопасности.
                    </div>
                    <div className="rule">
                        <strong>1.10</strong> При возникновении спорных ситуаций или ситуаций, отсутствующих в правилах,
                        администратор имеет право разрешить
                        ситуацию согласно своим умозаключениям.
                        на сервере.
                    </div>
                    <div className="rule">
                        <strong>1.11</strong> Ресурсы проекта бесплатны и работают по принципу «как есть» без каких-либо
                        гарантий правильной работы. Ни при каких
                        условиях Администрация не обязана возвращать вещи утраченные из-за непредвиденных обстоятельств
                        (багов, откатов,
                        вайпов, краж, очисток и т.д.).
                    </div>
                    <div className="rule">
                        <strong>1.12</strong> Администрация оставляет за собой право не раскрывать подробности работы
                        системы автоматического наказания
                        игроков (далее – античит, либо – система) в целях обеспечения безопасности этой системы и
                        предотвращения их обхода от
                        недобросовестных Пользователей.
                    </div>
                    <div className="rule">
                        <strong>1.13</strong> В случае неоднократного нарушения правил, а в особенности одного и того же
                        пункта правил, пользователь может
                        получить более строгое наказание вплоть до перманентного бана.
                    </div>
                    <div className="rule">
                        <strong>1.14</strong> Администрация может использовать сторонные ресурсы, социальные сети
                        (Discord,
                        Вконтакте, YouTube и т.д.) с целью
                        выдачи наказаний и сбора информации.
                    </div>
                    <div className="rule">
                        <strong>1.15</strong> Каждый пользователь обязан руководствоваться нормами поведения в обществе,
                        принципами справедливости и честной
                        игры.
                    </div>
                </div>


                <h1>2. ПРАВИЛА РЕЖИМА VANILLA</h1>
                <div className="rules-content">
                    <div className="rule">
                        <strong>2.1</strong> Соблюдайте адекватное поведение. Помните, что это многопользовательская
                        среда, а
                        не ваш собственный сервер. Всегда учитывайте, какие последствия могут возникнуть из-за ваших
                        действий для других игроков.
                        <h3>Наказание:</h3> Блокировка игрового аккаунта сроком до 1 года.
                    </div>
                    <div className="rule">
                        <strong>2.2</strong> Запрещается совершение любых действий, направленных на обман, использование
                        читов или уязвимостей сервера.
                        <h3>Наказание:</h3> Блокировка игрового аккаунта сроком до 1 года на усмотрение Администрации.
                    </div>
                    <div className="rule">
                        <strong>2.3</strong> Запрещено гриферство, вмешательство в имущество других игроков и воровство.
                        Все
                        изменения в блоках записываются и регистрируются. Мы сможем установить вашу ответственность за
                        эти
                        действия и быстро откатить ущерб, а также наказать вас перманентным баном.
                        <h3>Наказание:</h3> Блокировка игрового аккаунта сроком до 1 года на усмотрение Администрации.
                    </div>
                    <div className="rule">
                        <strong>2.4</strong> Запрещена целенаправленная реклама стороннего проекта, организации, канала
                        или
                        блога не относящийся к проекту PfauMC в любом его виде, в том числе скрытая реклама, без
                        разрешения
                        Администрации проекта.
                        <h3>Наказание:</h3> Блокировка игрового аккаунта до 30д.
                    </div>
                    <div className="rule">
                        <strong>2.5</strong> Пользователю запрещается продавать или обменивать игровые ресурсы, на
                        реальные
                        деньги, в том числе скрыто или
                        завуалированно просить перейти в другой чат или приложение, для совершения такового действия.
                        <h3>Наказание:</h3> Блокировка игрового аккаунта до 30д.
                    </div>
                    <div className="rule">
                        <strong>2.6</strong> Пользователю запрещается продавать, передавать или обменивать Аккаунт.
                        <h3>Наказание:</h3> Блокировка по IP навсегда.
                    </div>
                    <div className="rule">
                        <strong>2.7</strong> Пользователю запрещено использовать Мультиаккаунты с целью обхода
                        временного
                        наказания, выданного на основной Аккаунт Пользователя.
                        <h3>Наказание:</h3> Блокировка по IP до 30 дней.
                    </div>
                    <div className="rule">
                        <strong>2.8</strong> Пользователю запрещается выдавать себя за Staff и администрацию проекта при
                        любых обстоятельствах,
                        регистрировать никнеймы, ставить аватары или скины, прямо или косвенно содержащие в себе
                        ненормативную лексику,
                        нецензурные выражения, носящие эротический или аморальный характер, свастику и политический
                        контент,
                        также,
                        нарушающие другие пункты , либо же содержащие явную отсылку к Staff или администрации проекта.
                        <h3>Наказание:</h3> Блокировка навсегда.
                    </div>
                    <div className="rule">
                        <strong>2.9</strong> Пользователю запрещено уклоняться от проверок, провоцировать на активные
                        действия без уважительных причин и не
                        выполнять другие правомерные требования Staff или администрации проекта. Также запрещено
                        целенаправленно
                        отвлекать или препятствовать Staff’y и администрации.
                        <h3>Наказание:</h3> Блокировка до 48 часов.
                    </div>
                    <div className="rule">
                        <strong>2.10</strong> Запрещается разжигать межнациональную, религиозную, гендерную рознь,
                        обсуждать
                        политику, публиковать аморальный
                        и 18+ контент, а также дискриминировать, оскорблять, унижать, буллить и кибербуллить (в том
                        числе
                        родственников),
                        подстрекать на нарушения, а так же спамить, флудить, многократно повторять сообщения, команды
                        или
                        растягивать текст.
                        <h3>Наказание:</h3> Предупреждение.
                        <h4>Рецидив:</h4> Блокировка до 4 часов.
                    </div>
                    <div className="rule">
                        <strong>2.11</strong> Пользователю запрещены безосновательные негативные высказывания, осуждения
                        и
                        нецелесообразные оценки сервера,
                        действий Администрации или Staffa и их соответственно, вне специально отведенных для этого мест,
                        а
                        так же носящие под собой вред деловой репутации проекта.
                        <h3>Наказание:</h3>Блокировка до 2 дней.
                        <h4>Рецидив:</h4> Блокировка до 7 дней.
                    </div>
                    <div className="rule">
                        <strong>2.12</strong> Пользователю запрещается обманывать других пользователей в процессе обмена
                        внутриигровых ценностей.
                        <h3>Наказание:</h3>Блокировка до 7 дней
                    </div>
                </div>

                <h1>3. ПРАВИЛА ЛИЧНЫХ ТЕРРИТОРИЙ</h1>
                <div className="rules-content">
                    <div className="rule">
                        <strong>3.1</strong> Запрещено занимать центральную территорию по всей высоте в радиусе 600
                        блоков от нулевых
                        координат (X:0, Z:0) в обычном мире, и в радиусе 75 блоков в Незере.
                        <h3>Наказание:</h3>Снос постройки
                    </div>
                    <div className="rule">
                        <strong>3.2</strong> За пределами центральной территории разрешено строить на любой незанятой
                        территории, или на
                        территории другого игрока с его согласия, при условии соблюдения правил владельца территории,
                        которые не могут
                        противоречить правилам сервера.
                    </div>
                    <div className="rule">
                        <strong>3.3</strong> Для занятия новой территории необходимо:
                        <ul>
                            <li>Явно обозначить ее границы, например, с помощью заборов, костров или других видимых и
                                однозначных средств.
                            </li>
                            <li>Рекомендуется на видном месте обозначить табличкой чья эта территория.</li>
                        </ul>
                        Территория, которая была обозначена по правилам, считается занятой по всей высоте.
                    </div>
                    <div className="rule">
                        <strong>3.4</strong> Если вы хотите занять территорию, и в радиусе 300 блоков в обычном мире и в
                        радиусе 40 блоков в
                        Незере есть уже занятая территория — вы должны договориться с её владельцем, и заключить
                        контракт, в котором он
                        подтвердит, что он не против. Контракт делается при помощи книжки в двух экземплярах.
                        <h4>Исключение:</h4>Договариваться о постройке ответвления от основной ветки рядом
                        с другим
                        ответвлением не нужно.
                        Ситуации, в которых игрок строит на уже занятой территории, будут
                        рассматриваться
                        индивидуально, так как виноватым может быть тот, кто недостаточно ясно отметил территорию, или
                        тот, кто не заметил
                        отметок.
                    </div>

                    <div className="rule">
                        <strong>3.5</strong> Правила нахождения и проживания на территории устанавливает владелец. Они
                        не могут нарушать
                        правила сервера и должны быть указаны на видном месте.
                        <h3>Наказание:</h3>За установление правил, которые нарушают правила проетка - блокировка до 7ми
                        дней.
                    </div>
                    <div className="rule">
                        <strong>3.6</strong> Вся территория или её часть может быть полностью закрыта, если она принадлежит к какому либо городу.
                        Необходимо установить таблички на видном месте перед входом в территорию, указывающие правила
                        доступа и возможные
                        последствия нарушений.
                        <h4>Уточнение:</h4>Игроки, нарушившие режим закрытой территории, могут быть убиты жителями
                        этой территории. Жители обязаны вернуть игроку все его вещи.
                    </div>
                    <div className="rule">
                        <strong>3.7</strong> Ограничения на вход на территорию могут быть наложены на конкретных
                        игроков и города.
                    </div>
                    <div className="rule">
                        <strong>3.8</strong> Владелец города не может налагать штрафы или уничтожать постройки
                        игроков, проживающих на
                        его территории, без их согласия.
                        <h4>Исключение:</h4>Владелец территории без города может управлять земельным участком как ему угодно.
                    </div>
                    <div className="rule">
                        <strong>3.9</strong> Если игрок не посещает игру более 21 дня, его территория
                        признана бесхозной и
                        передана под управление Администрации.
                    </div>
                    <div className="rule">
                        <strong>3.10</strong> Игрок может передать свою территорию другому игроку, если планирует долгое
                        отсутствие или был
                        заблокирован более 21 день. Передача возможна исключительно с разрешения Администрации.
                    </div>
                </div>


                <h1>4. ПРАВИЛА БИЗНЕСОВ</h1>
                <div className="rules-content">
                    <div className="rule">
                        <strong>4.1</strong> Каждый игрок имеет право построить по 1 виду каждого бизнеса. Виды
                        бизнесов: магазин, казино, аукцион, обменник валют/предметов, банк.
                    </div>
                    <div className="rule">
                        <strong>4.2</strong> При аренде чего-либо необходимо создать контракт и подписать его в двух
                        экземплярах в игре.
                        Игроки сами договариваются (в контракте) о стоимости аренды, времени выплаты и пр.
                    </div>
                    <div className="rule">
                        <strong>4.3</strong> Территория/блок хранения начинает считаться бизнесом с момента, когда
                        ставят табличку с
                        условиями покупки/услуги ("что, сколько, цена" и другое).
                    </div>
                    <div className="rule">
                        <strong>4.4</strong> В случае блокировки владельца — территории с бизнесами замораживаются.
                    </div>
                    <div className="rule">
                        <strong>4.5</strong> Заблокированный владелец бизнеса может передать его другому игроку, если
                        бизнес находится на
                        личной территории и он соответствует требованиям обозначения личных территорий. 
                        Передача возможна исключительно с разрешения Администрации.
                    </div>
                    <div className="rule">
                        <strong>4.6</strong> Бизнес, владельца которого забанили, и бизнес находится на территории
                        другого игрока,
                        также замораживается. Дальнейшие действия с ним нужно согласовывать с Администрацией.
                    </div>
                    <div className="rule">
                        <strong>4.7</strong> Контроль за товарами осуществляет владелец бизнеса.
                    </div>
                    <div className="rule">
                        <strong>4.8</strong> Репорт на кражу в бизнесе, который находится за пределами спавна, можно подать только по факту нарушения. Возврат
                        за украденные
                        вещи не осуществляется.
                    </div>
                    <div className="rule">
                        <strong>4.9</strong> Запрещено строить свои бизнесы в Энде/Аду и на спавне в радиусе 600
                        блоков. Вы можете
                        построить отдельный портал к магазину, но размещать товары непосредственно в нижнем мире
                        запрещено. На спавне выделяет администрация территорию для вашего магазина.
                    </div>
                    <div className="rule">
                        <strong>4.10</strong> На сервере официально принятая валюта — алмазы. Наша рекомендуемая форма
                        валюты — алмазы
                        либо бартер, но право выбора остается за игроком.
                    </div>
                    <div className="rule">
                        <strong>4.11</strong> Покупатель должен заплатить полную стоимость товара сразу. Частичная
                        оплата запрещается и
                        приравнивается к гриферству.
                    </div>
                    <div className="rule">
                        <strong>4.12</strong> Вернуть товар можно только с согласия продавца.
                    </div>
                </div>

                <h1>5. ПРАВИЛА ПРОВЕДЕНИЯ ВОЙН</h1>
                <div className="rules-content">
                    <div className="rule">
                        <strong>5.1</strong> Проведение войн (PvP) между городами, гильдиями и группами лиц допускается
                        только при
                        наличии подписанного соглашения между обеими сторонами (книжка в двух экземплярах).
                    </div>
                    <div className="rule">
                        <strong>5.2</strong> Соглашение о проведении войны должно быть подписано обеими сторонами и
                        содержать следующие
                        условия:
                        <ul>
                            <li>Даты и время, в течение которых ведется война.</li>
                            <li>Между кем ведется война, с указанием всех участников войны.</li>
                            <li>Территории, на которых разрешено проведение боевых действий и условия.</li>
                            <li>Условия окончания войны, включая условия перемирия и прекращения враждебностей.</li>
                        </ul>
                    </div>
                    <div className="rule">
                        <strong>5.3</strong> Во время войны обязательно соблюдение правил сервера и минимальное влияние
                        на игровую
                        экономику.
                    </div>
                    <div className="rule">
                        <strong>5.4</strong> Игроки, не участвующие в войне, должны иметь возможность избегать боевых
                        действий, если
                        они находятся за пределами зоны войны.
                    </div>
                    <div className="rule">
                        <strong>5.5</strong> Спорные ситуации и разрешение конфликтов между сторонами во время войны
                        решаются с
                        привлечением администрации сервера.
                    </div>
                    <div className="rule">
                        <strong>5.6</strong> В случае нарушения условий соглашения обе стороны обязаны предоставить
                        доказательства и
                        уведомить администрацию сервера через систему репортов.
                    </div>
                    <div className="rule">
                        <strong>5.7</strong> Администрация сервера оставляет за собой право вмешаться в конфликт для
                        обеспечения
                        безопасности и справедливости игрового процесса.
                    </div>
                </div>

                            <h1>6. ПРАВИЛА СОЗДАНИЯ ГОРОДОВ</h1>
<div className="rules-content">
    <div className="rule">
        <strong>6.1</strong> Один игрок может быть зарегистрирован только в одном городе. Это правило исключает участие в нескольких городах одновременно.
    </div>
    <div className="rule">
        <strong>6.2</strong> Город можно создать, если в нём проживает более трёх участников. Это минимальное количество для регистрации города.
    </div>
    <div className="rule">
        <strong>6.3</strong> Мэрия города должна находиться в шаговой доступности от портала, ведущего в город — на расстоянии не более 30 блоков.
    </div>
    <div className="rule">
        <strong>6.4</strong> В мэрии должна быть указана следующая информация:
        <ul>
            <li>Владелец города.</li>
            <li>Жители города.</li>
            <li>Правила пребывания в городе.</li>
        </ul>
    </div>
    <div className="rule">
        <strong>6.5</strong> Описание каждого города должно быть оставлено в специально отведённой теме Discord-сервера  
        <a href="https://discord.com/channels/1111732119618723890/1286005340655325236"> здесь</a>. В названии темы должно быть указано [Название города].
    </div>
    <div className="rule">
        <strong>6.6</strong> В описании города необходимо указать:
        <ul>
            <li>Владельца города.</li>
            <li>Жителей города.</li>
            <li>Общую информацию о городе (например, чем живет город, стиль строительства, планы).</li>
            <li>Минимум 3 скриншота города.</li>
        </ul>
    </div>
</div>

                            <h1>7. ПРАВИЛА И ОБЯЗАННОСТИ STAFF</h1>
<div className="rules-content">

    {/* Правила для Судьи */}
    <div className="rule">
        <h2>7.1 Обязанности Судьи</h2>
        <div className="rule">
            <strong>7.1.1</strong> Судья имеет право банить игроков за нарушение правил сервера.
        </div>
        <div className="rule">
            <strong>7.1.2</strong> Судья обязан разбирать конфликтные ситуации между игроками.
        </div>
        <div className="rule">
            <strong>7.1.3</strong> Судья отвечает за рассмотрение и обработку всех репортов, поступающих от игроков.
        </div>
    </div>

    {/* Правила для Священника */}
    <div className="rule">
        <h2>7.2 Обязанности Священника</h2>
        <div className="rule">
            <strong>7.2.1</strong> Священник имеет право разбанивать игроков при условии дальнейшего выполнения ими исправительных работ.
        </div>
        <div className="rule">
            <strong>7.2.2</strong> Священник обязан назначать исправительные работы для нарушителей перед разбаном.
        </div>
        <div className="rule">
            <strong>7.2.3</strong> Священник отвечает за проведение церемоний и следит за порядком в церкви на сервере.
        </div>
    </div>

    {/* Правила для Министра Финансов */}
    <div className="rule">
        <h2>7.3 Обязанности Министра Финансов</h2>
        <div className="rule">
            <strong>7.3.1</strong> Министр Финансов следит за экономической ситуацией на сервере, в том числе за торговлей на спавне.
        </div>
        <div className="rule">
            <strong>7.3.2</strong> Министр Финансов отвечает за управление бюджетом сервера и спонсирование ивентов.
        </div>

    </div>

    {/* Правила для Министра Транспорта */}
    <div className="rule">
        <h2>7.4 Обязанности Министра Транспорта</h2>
        <div className="rule">
            <strong>7.4.1</strong> Министр Транспорта отвечает за состояние хайперлупов и других транспортных систем сервера.
        </div>
        <div className="rule">
            <strong>7.4.2</strong> Министр обязан следить за исправностью всех транспортных веток, особенно в аду, и своевременно устранять поломки.
        </div>
        <div className="rule">
            <strong>7.4.3</strong> Министр Транспорта должен учитывать запросы игроков и предлагать улучшения для транспортной системы.
        </div>
    </div>

    {/* Правила для Инспекторов */}
    <div className="rule">
        <h2>7.5 Обязанности Инспекторов</h2>
        <div className="rule">
            <strong>7.5.1</strong> Инспекторы проверяют логи и доказательства по репортам, поступающим от игроков.
        </div>
        <div className="rule">
            <strong>7.5.2</strong> Инспекторы обязаны предоставлять судье и администрации доказательства нарушений для вынесения справедливого решения.
        </div>
        <div className="rule">
            <strong>7.5.3</strong> Инспекторы должны действовать беспристрастно и объективно при анализе каждого случая.
        </div>
    </div>

</div>



                <h1>МОДИФИКАЦИИ</h1>
                <div className="rules-content">
                    <div className="rule">
                        <strong><p className="p_1">Разрешенные моды:</p></strong>
                        <p>- Улучшения производительности (Lithium, Sodium, Phosphor и т. д.)</p>
                        <p>- Можно использовать моды, направленные на повышение производительности клиента без изменения
                            игрового процесса.</p>
                        <p>- Миникарты (VoxelMap, Xaero's Minimap и т. д.)</p>
                        <p>- Моды записи повторов (ReplayMod)</p>

                        <strong><p className="p_1">Прочие улучшения игры:</p></strong>
                        <p>- Модификации в целом приемлемы, если они не дают значительного игрового преимущества в
                            ситуациях
                            PvE или PvP, а также в выживании.</p>
                        <strong><p className="p_1">Допустимые примеры:</p></strong>
                        <p>- Сортировка инвентаря</p>
                        <p>- Состояние брони HUD</p>
                        <p>- Отображение информации о целевом блоке</p>
                        <p>- Индикаторы уровня освещенности</p>
                        <p>Все, что показывает информацию, которую игра уже сообщила вам в другом месте, например,
                            на экране
                            отладки (F3).</p>
                        <strong><p className="p_1">Недопустимые примеры:</p></strong>
                        <p>- Любые читы</p>
                        <p>- Автокликеры и другие автоматические действия.</p>
                        <p>- Автоматическая замена, экипировка или создание предметов без участия игрока</p>
                        <p>- Скрипты или макросы</p>
                        <p>- Модификации-схемы, которые отображают «голограммы» для помощи в строительстве,
                            разрешены при
                            условии, что они не автоматизируют какую-либо часть самого процесса
                            строительства.</p>
                    </div>
                </div>

                <h1>ПРАВИЛА ПОСТРОЙКИ ЛЕДЯНЫХ МАГИСТРАЛЕЙ (ХАЙПЕРЛУПОВ)</h1>
                <div className="rules-content">
                    <div className="rule">
                        <strong><p className="p_1">Местоположение хаба и веток.</p></strong>
                        <ul>
                            <li>Хаб находится на нулевых координатах (0; 62; 0).</li>
                            <li>Ветки находятся на 62 высоте.</li>
                            <li>Лёд находится на 61 высоте.</li>
                            <li>Из хаба выходят 4 основных ветки в каждую из сторон:
                                <ul>
                                    <li>север [Z-]</li>
                                    <li>юг [Z+]</li>
                                    <li>запад [X-]</li>
                                    <li>восток [X+]</li>
                                </ul>
                            </li>
                        </ul>
                        <div className="image-container"><img src="./img/Hell1.png" alt="Местоположение хаба и веток"/>
                        </div>
                    </div>
                    <div className="rule">
                        <strong><p className="p_1">Как присоединить портал</p></strong>
                        к веткам?
                        <ul>
                            <li>Когда вы устанавливаете свой портал в Нижнем мире и генерируете новый - первым делом
                                переместите его в аду на 62 высоту.
                            </li>
                            <li>Далее нужно присоединиться к ближайшей ветке.</li>
                            <li>Пример:
                                <ul>
                                    <li>Предположим, что ваш портал находится на координатах X: 128 Z: -63.</li>
                                    <li>Нужно чтобы большая часть пути к вашему порталу проходила по основной ветке.
                                        Берём каждую из координат в модуль — получается 128 и 63. Меньшая координата
                                        это 63, значит именно по ней будет идти ваш личный туннель.
                                    </li>
                                    <li>Вам нужно прокопаться к ветке X+, — это ближайшая ветка к вашему порталу. От
                                        портала нужно прокопать ровный тоннель к координатам X: 128 Z: 0.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div className="image-container"><img src="../img/Hell2.png" alt="Присоединение к веткам"/>
                        </div>
                        <div>Ветка прокопана правильно:</div>
                        <div className="image-container"><img src="../img/Hell3.png" alt="Правильная ветка"/></div>
                        <div>Ветка прокопана неправильно:</div>
                        <div className="image-container"><img src="../img/Hell4.png" alt="Неправильная ветка"/></div>
                    </div>
                    <div className="rule">
                        <strong><p className="p_1">Почему так нельзя?</p></strong>
                        <ul>
                            <li>Основные ветки уже прокопаны и оформлены. Вам пришлось бы прокапывать и оформлять
                                128 блоков туннеля вместо 63.
                            </li>
                            <li>Проще находить портал:
                                <ul>
                                    <li>Достаточно назвать координаты, чтобы игрок знал, как к вам попасть.</li>
                                    <li>Не нужно уточнять на какой ветке ваш портал.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="rule">
                        <strong><p className="p_1">Оформление основных веток и
                            ответвлений игроков.</p></strong>
                        <ul>
                            <li>Основные ветки, выходящие из хаба, оформляются в едином стиле (возможна разная
                                цветовая палитра).
                            </li>
                            <li>Ответвления от основной ветки игроки оформляют в свободной форме.</li>
                            <li>Минимальные требования к основным веткам и ответвлениям:
                                <ul>
                                    <li>Заполненный пол, боковые стенки и потолок.</li>
                                    <li>Полоса/полоски льда на полу для быстрого перемещения по ветке/ответвлению.
                                    </li>
                                    <li>Боковые бортики из полублоков по краям льда.</li>
                                    <li>Кнопки/плиты на льду.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>


                <SocialIcons/>
            </div>

        </div>


    );
};

export default RulesPage;
